.w-nav-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  color: #222222;
  padding: 1em;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.nav-link {
  width: auto;
  height: 1.5em;
  margin-right: 0px;
  margin-left: 40px;
  padding: 0;
  -webkit-transition: border 500ms ease, color 500ms ease, padding 500ms ease;
  transition: padding 500ms ease, border 500ms ease, color 500ms ease;
  /* font-family: Heebo; */
  color: #3372a9;
  font-weight: 300;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}

.nav-link:hover,
.nav-link-current {
  padding-top: 0;
  padding-bottom: 2em;
  border-bottom: 2px solid rgba(70, 100, 109, 0.5);
  color: #002635;
}

.navbar-item img {
  max-height: 100% !important;
}

.navbar-end {
  margin-top: 1.5em;
}
