@font-face {
  font-family: 'Kano';
  src: local('kano'), url(/static/media/Kano.04af2a5a.otf) format('opentype');
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px !important;
}

.section {
  padding: 3rem 1rem !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-item img {
  max-width: 210px !important;
}

.mainTitle {
  font-size: 3.5em !important;
  font-weight: 700 !important;
}

.mainColor {
  color: #3372a9;
  font-weight: bold;
}

.nav-link {
  font-size: 0.8em;
  font-family: 'Kano', 'Arial', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', sans-serif !important;
}

.separator {
  width: 50%;

  border-bottom: 2px solid rgba(70, 100, 109, 0.5);
  margin: 3rem 0 3rem auto;
}

.founderImage {
  width: 300px;
}

@media all and (max-width: 1087px) {
  .logo {
    padding: 1rem !important;
  }
}

@media all and (max-width: 980px) {
  .navbar {
    /* margin-top: 2em; */
    margin: 0;
    width: 100%;
    /* height: 100vh; */
  }

  .mainTitle {
    font-size: 2.5em !important;
  }

  .founderImage {
    width: 100%;
  }

  .navbar-end {
    height: 300px;
  }

  .logo {
    padding: 1rem !important;
  }

  .nav-link {
    padding: 2em 1em !important;
    display: block !important;
  }

  .separator {
    width: 90%;
    margin: 3rem auto;
  }
}

.content {
  line-height: 2rem;
  /* text-align: justify; */
}

.content > span {
  font-size: 0.9em;
}

/*---[The Duo-Tone Colour Quote]---*/
blockquote p {
  font-size: 0.8em;
  line-height: 1.4em;
  font-style: italic;
  letter-spacing: 1px;
  color: #666;
  font-weight: 300;
  margin-top: -50px;
}
blockquote.colour-quote {
  position: relative;
  border-left: none;
}
/*Quotation Mark*/
blockquote.colour-quote:before {
  content: '\201C';
  font-size: 90px;
  font-family: 'Alegreya', serif;
  background: -webkit-linear-gradient(
    180deg,
    #b6b8ba 60%,
    #b6b8ba 40%
  ); /*Change the Colours here*/
  -webkit-background-clip: text;
  color: transparent;
}

blockquote.colour-quote:after {
  content: '\201D';
  font-size: 90px;
  font-family: 'Alegreya', serif;
  position: absolute;
  right: 0;
  background: -webkit-linear-gradient(
    180deg,
    #b6b8ba 60%,
    #b6b8ba 40%
  ); /*Change the Colours here*/
  -webkit-background-clip: text;
  color: transparent;
  line-height: 80px;
}
/*Author*/
.colour-author {
  padding-top: 1em;
  /* padding-left: 20px; */
  font-size: 1em;
  text-transform: uppercase;
  line-height: 1.5em;
}
/*Mobile Settings Add CSS Class colour-wrap to Text Module*/
@media all and (min-width: 700px) {
  .colour-wrap {
    float: left;
    margin: 20px 20px 20px -10%;
  }
  blockquote.colour-quote {
    /* padding: 0 20px; */
    padding: 0;
    /* max-width: 300px; */
  }
}

@media all and (min-width: 1025px) {
  .container {
    padding: 0 5rem;
  }
}

.footer {
  margin-top: 5rem;
  background-color: #b6b8ba !important;
  color: white;
}

.footer p {
  font-size: 12px;
}

.footer h3 {
  color: white !important;
  border-bottom: 1px solid white;
  padding: 0.5em 0;
  font-size: 14px !important;
}

.navbar-burger span {
  height: 2px !important;
  background: #3372a9 !important;
}

.page-section {
  min-height: 60vh;
}

.w-nav-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  color: #222222;
  padding: 1em;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.nav-link {
  width: auto;
  height: 1.5em;
  margin-right: 0px;
  margin-left: 40px;
  padding: 0;
  -webkit-transition: border 500ms ease, color 500ms ease, padding 500ms ease;
  -webkit-transition: padding 500ms ease, border 500ms ease, color 500ms ease;
  transition: padding 500ms ease, border 500ms ease, color 500ms ease;
  /* font-family: Heebo; */
  color: #3372a9;
  font-weight: 300;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}

.nav-link:hover,
.nav-link-current {
  padding-top: 0;
  padding-bottom: 2em;
  border-bottom: 2px solid rgba(70, 100, 109, 0.5);
  color: #002635;
}

.navbar-item img {
  max-height: 100% !important;
}

.navbar-end {
  margin-top: 1.5em;
}

